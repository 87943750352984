import { Functions } from "@/firebase";
import {
  StripeBankPaymentMethod,
  StripeCardPaymentMethod,
  StripePaymentIntent
} from "@/types/Payment";
import { StripeMerchantInfo, TransactionCustomer } from "@sportango/backend";
import dayjs from "dayjs";
import { httpsCallable } from "firebase/functions";

export async function getPaymentIntent(
  paymentIntentId: string,
  merchantId: string
) {
  const getPaymentIntent = httpsCallable<
    {
      paymentIntentId: string;
      merchant: string;
    },
    StripePaymentIntent
  >(Functions, "getPaymentIntent");
  return (
    await getPaymentIntent({
      paymentIntentId,
      merchant: merchantId
    })
  ).data;
}

export function getCorrectStatusAndMessage(
  customerInfo?: TransactionCustomer,
  paymentIntent?: StripePaymentIntent
): { status: TransactionCustomer.StatusEnum; statusMessage: string } {
  let status: TransactionCustomer.StatusEnum | null = null;
  let statusMessage: string | null = null;

  switch (paymentIntent?.status) {
    case "processing":
      status = "processing";
      statusMessage = "We are currently processing this payment";
      break;
    case "succeeded":
      status = "success";
      statusMessage = "We have successfully processed this payment";
      break;
    case "requires_payment_method":
      status = "failed";
      statusMessage = "We failed to collect payment from this user";
      break;
    case "requires_action":
      status = "requiresAttention";
      statusMessage =
        "We need further steps to be taken by this player for completing this payment";
      break;
    case "canceled":
      status = "cancelled";
      statusMessage =
        "This payment was cancelled by us unfortunately, please retry";
      break;
  }
  if (!status) {
    if (customerInfo?.status === "failed") {
      status = "failed";
      statusMessage =
        customerInfo.errorMessage ||
        "An unknown error occurred while processing this payment";
    } else {
      status = "notStarted";
      statusMessage = "We have not started collecting payment from this user";
    }
  }
  if (customerInfo?.paidInCash) {
    status = "success";
    statusMessage = "This customer paid via cash";
  }
  return {
    status,
    statusMessage: statusMessage || ""
  };
}

export function parsePaymentDate(
  date?: number,
  transactionCustomer?: TransactionCustomer
): {
  full: string;
  short: string;
} {
  if (transactionCustomer?.paidInCash && transactionCustomer.cashPaidDate) {
    date = transactionCustomer.cashPaidDate;
  } else {
    date = (date || 0) * 1000;
  }
  return {
    short: date ? dayjs(date).format("MM/DD/YYYY") : "--/--/----",
    full: date
      ? `Ran Payment on ${dayjs(date).format("MM/DD/YYYY")} at ${dayjs(
          date
        ).format("h:mm a")}`
      : "-"
  };
}

export const CONVENIENCE_FEE_PERCENTAGE = 0.035;
export function getRightConvenienceFee(
  status: TransactionCustomer.StatusEnum,
  expectedAmount: number,
  defaultPaymentMethod:
    | StripeBankPaymentMethod
    | StripeCardPaymentMethod
    | null,
  transactionCustomer?: TransactionCustomer,
  merchantInfo?: StripeMerchantInfo
): number {
  let expectedFee = 0;
  if (defaultPaymentMethod !== null) {
    let fee = 0;
    if ((defaultPaymentMethod as StripeCardPaymentMethod).brand) {
      fee = merchantInfo?.convenienceFees?.cards || CONVENIENCE_FEE_PERCENTAGE;
    } else if ((defaultPaymentMethod as StripeBankPaymentMethod).bank_name) {
      fee = merchantInfo?.convenienceFees?.ach || 0;
    }
    expectedFee = Number(
      (Number(Number(expectedAmount).toFixed(2)) * fee).toFixed(2)
    );
  }
  if (status === "success" && transactionCustomer?.convenienceFee) {
    expectedFee = transactionCustomer.convenienceFee;
  }
  if (transactionCustomer?.paidInCash) {
    expectedFee = 0;
  }
  return Number(expectedFee);
}

export function getRightPaymentMethod(
  paymentIntent: StripePaymentIntent | null
) {
  if (paymentIntent?.payment_method !== null) {
    return paymentIntent?.payment_method;
  } else if (
    paymentIntent.last_payment_error &&
    paymentIntent.last_payment_error.payment_method
  ) {
    return paymentIntent.last_payment_error.payment_method.id;
  }
  return null;
}
