import { Functions } from "@/firebase";
import {
  CheckoutPaymentMethod,
  CommonPaymentMethod,
  StripeBankPaymentMethod,
  StripeCardPaymentMethod,
  StripePaymentIntent
} from "@/types/Payment";
import { getPaymentIntent, getRightPaymentMethod } from "@/utils/payments";
import {
  generateEventsFromPrograms,
  Program,
  ProgramPlayer,
  ProgramPlayerRequests,
  Transaction
} from "@sportango/backend";
import axios, { AxiosInstance } from "axios";
import { httpsCallable } from "firebase/functions";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

export interface UsablePaymentIntent extends StripePaymentIntent {
  userId: string;
  merchantId: string;
}

@Component
export class SharedPaymentIntentsWithPaymentMethod extends Vue {
  public paymentIntentsLoaded_ = false;
  public paymentIntents_: Array<UsablePaymentIntent> = [];

  public paymentMethods_: Array<CommonPaymentMethod> = [];
  public paymentMethodsLoaded_ = true;

  public paymentIntentIdsLength = 0;
  private stripeApiClient_: AxiosInstance;
  constructor() {
    super();
    if (process.env.NODE_ENV === "production") {
      this.stripeApiClient_ = axios.create({
        baseURL: `${window.location.origin}/stripe`
      });
    } else {
      if (process.env.VUE_APP_STRIPE_URL) {
        this.stripeApiClient_ = axios.create({
          baseURL: `${process.env.VUE_APP_STRIPE_URL}/stripe`
        });
      } else {
        throw new Error(
          "Please set VUE_APP_STRIPE_URL in .env.development file"
        );
      }
    }
  }

  @Watch("paymentIntents_")
  onPaymentIntents_Changed(newVal: Array<UsablePaymentIntent>) {
    if (newVal.length === this.paymentIntentIdsLength && newVal.length !== 0) {
      this.paymentIntentsLoaded_ = true;
    }
  }
  @Watch("paymentMethods_")
  onPaymentMethods_Changed(newVal: Array<CommonPaymentMethod>) {
    if (newVal.length === this.paymentIntentIdsLength) {
      // this.paymentMethodsLoaded_ = true;
    }
  }

  public getPaymentIntentsWithPaymentMethods(transactions: Array<Transaction>) {
    this.paymentIntentsLoaded_ = false;
    // this.paymentMethodsLoaded_ = false;
    // this function will get all payment intents and store them into the array, along with uid of customer
    const paymentIntentIds: Array<{
      userId: string;
      paymentIntentId: string;
      merchantId: string;
    }> = [];
    transactions.forEach((t) => {
      t.customers?.forEach((c) => {
        if (c.paymentIntentId && c.uid && t.merchantId) {
          paymentIntentIds.push({
            userId: c.uid,
            paymentIntentId: c.paymentIntentId,
            merchantId: t.merchantId
          });
        }
      });
    });
    this.paymentIntentIdsLength = paymentIntentIds.length;
    const paymentIntentIdsQueue = paymentIntentIds.map((p) =>
      this.getCustomerPaymentIntent_(p.userId, p.paymentIntentId, p.merchantId)
    );
    paymentIntentIdsQueue.forEach((p) => {
      p.then((intentData) => {
        // console.log(`Intent - ${intentData.id}`);
        this.paymentIntents_.push(intentData);
        this.getPaymentMethodForIntent_(intentData)
          .then(
            (paymentMethodData) =>
              paymentMethodData != null &&
              this.paymentMethods_.push(paymentMethodData)
            /* &&
              console.log(`Method for - ${intentData.id}`)*/
          )
          .catch((e) => this.paymentMethods_.push(e));
      });
    });
  }
  private async getCustomerPaymentIntent_(
    userId: string,
    paymentIntentId: string,
    merchantId: string
  ): Promise<UsablePaymentIntent> {
    const paymentIntent = await getPaymentIntent(paymentIntentId, merchantId);
    return {
      ...paymentIntent,
      userId,
      merchantId
    };
  }

  private async getPaymentMethodForIntent_(
    paymentIntent: UsablePaymentIntent
  ): Promise<CommonPaymentMethod | null> {
    const paymentMethodId = getRightPaymentMethod(paymentIntent);
    if (paymentMethodId === null || paymentMethodId === undefined) {
      return null;
    }
    try {
      const paymentMethod = await this.stripeApiClient_.get<
        StripeBankPaymentMethod | StripeCardPaymentMethod
      >(`paymentMethods/${paymentMethodId}`, {
        headers: {
          "Stripe-Account": paymentIntent.merchantId
        }
      });
      return paymentMethod.data;
    } catch (err) {
      return {
        id: paymentMethodId,
        isCheckout: true
      };
    }
  }
}

/**
 * @deprecated This component is no longer acceptable to get payment
 *   intents.Replaced with {@link_SharedPaymentIntentsWithPaymentMethod} > Use
 *   {@link getPaymentIntentsWithPaymentMethods} instead to get paymentIntents &
 *   paymentMethods.
 */
@Component
export class SharedPaymentIntents extends Vue {
  public paymentIntentsLoaded = false;
  public paymentIntents: Array<UsablePaymentIntent> = [];

  public async getPaymentIntents(transactions: Array<Transaction>) {
    this.paymentIntentsLoaded = false;
    // this function will get all payment intents and store them into the array, along with uid of customer
    const paymentIntentIds: Array<{
      userId: string;
      paymentIntentId: string;
      merchantId: string;
    }> = [];
    transactions.forEach((t) => {
      t.customers?.forEach((c) => {
        if (c.paymentIntentId && c.uid && t.merchantId) {
          paymentIntentIds.push({
            userId: c.uid,
            paymentIntentId: c.paymentIntentId,
            merchantId: t.merchantId
          });
        }
      });
    });
    this.paymentIntents = await Promise.all(
      paymentIntentIds.map((p) =>
        this.getCustomerPaymentIntent(p.userId, p.paymentIntentId, p.merchantId)
      )
    );
    this.paymentIntentsLoaded = true;
  }

  private async getCustomerPaymentIntent(
    userId: string,
    paymentIntentId: string,
    merchantId: string
  ): Promise<UsablePaymentIntent> {
    const paymentIntent = await getPaymentIntent(paymentIntentId, merchantId);
    return {
      ...paymentIntent,
      userId,
      merchantId
    };
  }
}

@Component
export class SharedPaymentMethods extends Vue {
  public paymentMethods: Array<CommonPaymentMethod> = [];
  public paymentMethodsLoaded = true;
  public defaultPaymentMethodsLoaded = true;
  public defaultPaymentMethods: Array<
    StripeBankPaymentMethod | StripeCardPaymentMethod
  > = [];
  private stripeApiClient: AxiosInstance;
  constructor() {
    super();
    if (process.env.NODE_ENV === "production") {
      this.stripeApiClient = axios.create({
        baseURL: `${window.location.origin}/stripe`
      });
    } else {
      if (process.env.VUE_APP_STRIPE_URL) {
        this.stripeApiClient = axios.create({
          baseURL: `${process.env.VUE_APP_STRIPE_URL}/stripe`
        });
      } else {
        throw new Error(
          "Please set VUE_APP_STRIPE_URL in .env.development file"
        );
      }
    }
  }

  /**
   * @deprecated This method is no longer acceptable to get payment methods
   *   .Replaced with {@link_SharedPaymentIntentsWithPaymentMethod} > Use
   *   {@link getPaymentIntentsWithPaymentMethods} instead to get paymentIntents
   *   & paymentMethods.
   */
  public async getPaymentMethodsForIntents(
    paymentIntents: Array<UsablePaymentIntent>
  ) {
    this.paymentMethodsLoaded = false;
    this.paymentMethods = [];
    const paymentMethods = await Promise.all(
      paymentIntents.map((p) => this.getPaymentMethodForIntent(p))
    );
    paymentMethods.forEach((p) => {
      if (p !== null) {
        this.paymentMethods.push(p);
      }
    });
    this.paymentMethodsLoaded = true;
  }
  /**
   * @deprecated This method is no longer acceptable to get payment methods
   *   .Replaced with {@link_SharedPaymentIntentsWithPaymentMethod} > Use
   *   {@link getPaymentIntentsWithPaymentMethods} instead to get paymentIntents
   *   & paymentMethods.
   */
  private async getPaymentMethodForIntent(
    paymentIntent: UsablePaymentIntent
  ): Promise<CommonPaymentMethod | null> {
    const paymentMethodId = getRightPaymentMethod(paymentIntent);
    if (paymentMethodId === null || paymentMethodId === undefined) {
      return null;
    }
    try {
      const paymentMethod = await this.stripeApiClient.get<
        StripeBankPaymentMethod | StripeCardPaymentMethod
      >(`paymentMethods/${paymentMethodId}`, {
        headers: {
          "Stripe-Account": paymentIntent.merchantId
        }
      });
      return paymentMethod.data;
    } catch (err) {
      const checkoutPaymentMethod: CheckoutPaymentMethod = {
        id: paymentMethodId,
        isCheckout: true
      };
      return checkoutPaymentMethod;
    }
  }

  public async getDefaultPaymentMethods(
    customerIds: Array<string>,
    merchantId: string
  ) {
    this.defaultPaymentMethodsLoaded = false;
    this.defaultPaymentMethods = [];
    const defaultPaymentMethods = await Promise.all(
      customerIds.map((c) => this.getPaymentMethodForCustomer(c, merchantId))
    );
    defaultPaymentMethods.forEach((d) => {
      if (d !== null) {
        this.defaultPaymentMethods.push(d);
      }
    });
    this.defaultPaymentMethodsLoaded = true;
  }

  private async getPaymentMethodForCustomer(
    customerId: string,
    merchantId: string
  ): Promise<StripeBankPaymentMethod | StripeCardPaymentMethod | null> {
    try {
      const getDefaultPaymentMethod = httpsCallable<
        {
          customer: string;
          merchantId: string;
        },
        StripeBankPaymentMethod | StripeCardPaymentMethod | { message: string }
      >(Functions, "getDefaultPaymentMethodForCustomer");
      if (customerId && merchantId) {
        const paymentMethod = await getDefaultPaymentMethod({
          customer: customerId,
          merchantId
        });
        if (!paymentMethod.data) {
          return null;
        } else if ((paymentMethod.data as { message: string }).message) {
          return null;
        } else {
          return {
            ...(paymentMethod.data as
              | StripeBankPaymentMethod
              | StripeCardPaymentMethod),
            customer: customerId
          };
        }
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  }
}

@Component
export class ProrationMixin extends Vue {
  calculateProgramPlayerProratePrice(
    playerInfo: ProgramPlayer | ProgramPlayerRequests,
    programInfo: Program,
    currentPrice: number | string = this.calculatePlayerPriceFromProgram(
      programInfo,
      playerInfo
    )
  ): number {
    if (playerInfo.startDate && playerInfo.days) {
      const programEvents = generateEventsFromPrograms(
        {
          ...programInfo,
          days: playerInfo.days
        },
        ""
      ).length;
      const playerEvents = generateEventsFromPrograms(
        {
          ...programInfo,
          startDate: playerInfo.startDate,
          days: playerInfo.days
        },
        ""
      ).length;
      return Number(
        (Number(currentPrice) * (playerEvents / programEvents)).toFixed(2)
      );
    }
    return 0;
  }

  calculatePlayerPriceFromProgram(
    programInfo: Program,
    playerInfo: ProgramPlayer | ProgramPlayerRequests
  ): number {
    return (
      programInfo.prices?.find(
        (p) => p.timesPerWeek === playerInfo.days?.length
      )?.daysPrice || 0
    );
  }
}
